import React from "react";
import {ProjectsArray} from "./ProjectsArray.js"
import "./projects.scss";




function Projects() {
  // let sorted = (a, b) => {
  //   if (a.id < b.id) {
  //     return isSorted ? -1 : 1
  //   } else if (a.id > b.id) {
  //     return isSorted ? 1 : -1
  //   }
  // }

  return (
    <div className="projects">
      <div className="apps">          
        {ProjectsArray?.map((item,i) => (
          <div key={i} className="singleApp">
            <a title="This a screenshot of the project" className="imgAnchor"><img src={item?.img}/></a>
            <h4 title="The name of this project">{item?.name}</h4>
            <a className="buttonVisit" target="_blank" rel="noreferrer" href={item?.gh} title="Visit this website">Visit</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;