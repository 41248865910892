import restaurant from "./restaurant.png"
import anCourses from "./an-courses.png"
import socialWebsite from "./social.png"
import clothesNew from "./clothes-new.png"
export const ProjectsArray = [
    {
        name: "Clothes-new",
        img: clothesNew,
        gh:"https://clothes-a94c9.web.app/"
      },
      {
        name: "Social-website",
        img: socialWebsite,
        gh:"https://social-app-anwar.web.app/"
      },
      {
        name: "An-Courses",
        img: anCourses,
        gh:"https://an-courses.vercel.app/"
      },
      {
        name: "Restaurant",
        img: restaurant,
        gh:"https://re-anwar.web.app/"
      },
      {
        name: "Fullstack-todo-list",
        img: "https://cdn.pixabay.com/photo/2016/11/22/23/09/fountain-pen-1851096_960_720.jpg",
        gh:"https://fullstack-todo-five.vercel.app/"
      },
      {
        name: "Clothes",
        img: "https://cdn.pixabay.com/photo/2016/11/19/15/40/clothes-1839935_960_720.jpg",
        gh:"https://fullstack-clothes.vercel.app/"
      },
      {
        name: "OnlineShop",
        img: "https://cdn.pixabay.com/photo/2019/10/10/08/11/shopping-4538982_960_720.jpg",
        gh:"https://commercial-website.vercel.app/"
      },
      {
        name: "todo-react",
        img: "https://us.123rf.com/450wm/sn333g/sn333g1601/sn333g160100034/50641564-to-do-list-vector-illustration-flat-reminder-concept-background-with-outline-icons.jpg?ver=6",
        gh: "https://anwar-todo.vercel.app/",
      },
      {
        name: "calculator",
        img: "https://st.depositphotos.com/1555678/2556/i/950/depositphotos_25567113-stock-photo-3d-open-calculator.jpg",
        gh: "https://atakriti.github.io/calculator/",
      },
      {
        name: "number game",
        img: "https://media.istockphoto.com/photos/yellow-number-two-glowing-amid-black-number-twos-on-black-background-picture-id1305169663?b=1&k=20&m=1305169663&s=170667a&w=0&h=WNagQtI7vYSweIGhGj7Fe0HhgYi50WSn9FPxgApypk8=",
        gh: "https://atakriti.github.io/number-game/",
      },
    
      {
        name: "Password Generator",
        img: "https://media.istockphoto.com/photos/password-input-field-with-padlock-picture-id808424876?b=1&k=20&m=808424876&s=170667a&w=0&h=jsHA-i698IpjyABBEA2Af_-n2U24x81UBrCNPXCmTgQ=",
        gh: "https://atakriti.github.io/password-generator/",
      },
      {
        name: "todo-list",
        img: "https://wallpaperaccess.com/full/1489353.jpg",
        gh: "https://atakriti.github.io/todo-list/",
      },
];