import React from 'react'
import "./whatIKnow.scss"
import bootstrap from "./code apps/bootstrap.png"
import css from "./code apps/css.png"
import git from "./code apps/git.png"
import github from "./code apps/github.png"
import html from "./code apps/html.png"
import js from "./code apps/js.png"
import react from "./code apps/react.png"
import sass from "./code apps/sass.png"
import mongodb from "./code apps/mongodb.png"
import nodejs from "./code apps/nodejs.png"
import fb from "./code apps/fb.png"
import tw from "./code apps/tw.png"
const logosArray = [
  {
    title:"Bootstrap",
    img:bootstrap
  },
  {
    title:"CSS",
    img:css
  },
  {
    title:"Git",
    img:git
  },
  {
    title:"Github",
    img:github
  },
  {
    title:"HTML",
    img:html
  },
  {
    title:"Javascript",
    img:js
  },
  {
    title:"REACT",
    img:react
  },
  {
    title:"SCSS",
    img:sass
  },
  {
    title:"MongoDB",
    img:mongodb
  },
  {
    title:"Nodejs",
    img:nodejs
  },
  {
    title:"Firebase",
    img:fb
  },
  {
    title:"Tailwind",
    img:tw
  },
]


function WhatIKnow() {
  return (
      <div className='main-know'>
        <h1>Tech skills</h1>
      <div className='container'>
              {
                  logosArray.map(item => (
                      <a title={item?.title}>
                      <img src={item?.img} alt="" />
                      </a>
                  ))
              }
         </div>
    </div>
  )
}

export default WhatIKnow