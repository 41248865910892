import React from "react";

import "./baner.scss";
import banerImage from "./baner.jpg";

function Baner() {
  return (
    <div className="baner">
      <div className="left">
        <div className="h1-bg">
          <h1>Anwar Dev</h1>
        </div>
        <h1>Web Developer</h1>
        <h2>Fast, Responsive, Elegance</h2>
        <p>My priorities of building website</p>
      </div>
      <div className="right">
        <div className="shapeOne"></div>

        <img src={banerImage} alt="" />
      </div>
    </div>
  );
}

export default Baner;
