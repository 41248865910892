import React from 'react'
import "./footer.scss"
function Footer() {
    let getDate = new Date()
  let year = getDate.getFullYear() 
   
  return (
      <footer>
          <h4>Anwar-dev {year}</h4>       
    </footer>
  )
}

export default Footer