import React, { useContext, useEffect } from 'react'
import "./popupUpdates.scss"
import { context } from '../Context'
import AOS from "aos";
import "aos/dist/aos.css";
function PopupUpdates() {
  const {setShowPopupUpdates} = useContext(context)

// ============================
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className='popupUpdates' >
     <div className='popupUpdatesContainer' data-aos="zoom-in">
       <span><button onClick={() => setShowPopupUpdates(false)}>X</button></span>
       <h1>New Update</h1>
       <p>The new version is made much easier for the customers, you can easily find out the projects by scrolling down a little bit. </p>
     </div>
    </div>
  )
}

export default PopupUpdates