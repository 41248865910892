import Baner from "./Baner";
import Footer from "./Footer";
import Header from "./Header";
import WhatIKnow from "./WhatIKnow";
import Projects from "./Projects"
import Contact from "./Contact";
import { useContext } from "react";
import { context } from "./Context";
import PopupUpdates from "./Popup/PopupUpdates";
function Home() {
  const {showPopupUpdates} = useContext(context)
  return (
    <div className="App">
      {showPopupUpdates && <PopupUpdates/>}
      <Header />
      <Baner />
      <Projects/>
      <Contact/>
      <WhatIKnow />
      <Footer/>
    </div>
  );
}

export default Home;
