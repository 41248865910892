import React from 'react'

import Form from "./Form"
import "./contact.scss"
import github from "./code apps/github-white.png"
function Contact() {
  return (
      <div className='contact'>
      <Form />
      <div className="info">
        {/* email */}
        <span className='spanEmail'>
          <h2>E-Mail</h2>
        <a rel='noreferrer' target="_blank"  href="mailto:anwart256@gmail.com">anwart256@gmail.com</a>
        </span>
        {/* logos */}
        <span className='spanLogo'>
          <a rel='noreferrer' target="_blank" href="https://github.com/atakriti">
          <img src={github} alt="" />
          </a>

        </span>
        
      </div>
        
    </div>
  )
}

export default Contact