import React,{createContext} from 'react'
import useLocalStorage from "use-local-storage";
export const context = createContext()
function Context({children}) {
  const [showPopupUpdates,setShowPopupUpdates] = useLocalStorage("showPopupUpdates",true)
  return (
    <context.Provider value={{showPopupUpdates,setShowPopupUpdates}}>{children}</context.Provider>
  )
}

export default Context