import React from "react";
import { Link } from "react-router-dom";
// Style
import "./header.scss";

import logo from "./myLogo.png";


// Logo
function Header() {
  // const [offset, setOffset] = useState(0);
  // useEffect(() => {
  //     const onScroll = () => setOffset(window.pageYOffset);
  //     // clean up code
  //     window.removeEventListener('scroll', onScroll);
  //     window.addEventListener('scroll', onScroll, { passive: true });
  //     return () => window.removeEventListener('scroll', onScroll);
  // }, []);
  return (
    <header >
      <div className="logoContainer">
      <a className="logo" to={"/"}>
          <img src={logo} alt="" />
        </a>
      </div>
      
    </header>
  );
}

export default Header;
