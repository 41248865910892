import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import "./form.scss";
import myLogo from "./myLogo.png";
import axios from "axios";
function Form() {
  let [isRecaptcha, setIsRecaptcha] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsValue, setInputsValue] = useState({
    username: "",
    email: "",
    phonenumber: "",
    textarea: "",
  });
  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       process.env.REACT_APP_SERVICE,
  //       process.env.REACT_APP_TEMPLATE,
  //       e.target,
  //       process.env.REACT_APP_KEY
  //     )
  //     .then(
  //       (result) => {
  //         alert("Was successfully sent");
  //       },
  //       (error) => {
  //         alert("Was not sent");
  //       }
  //     );

  //   e.target.reset();
  // };
  const handleChange = (e) => {
    setInputsValue({ ...inputsValue, [e.target.name]: e.target.value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      setIsSending(true);
      await axios.post("https://nodemailer-restaurant.vercel.app/contactMe", inputsValue);
      await axios.post("https://nodemailer-restaurant.vercel.app/contactMeReply", inputsValue);
      setIsSending(false);

      setInputsValue({
        username: "",
        email: "",
        phonenumber: "",
        textarea: "",
      });
    } catch (error) {
      alert("Please try again later");
      setIsSending(false);
    }
  };

  return (
    <div className="form-main">
      <div className="form">
        <h1>Contact me</h1>
        <form action="" onSubmit={sendEmail} onChange={handleChange}>
          <input
            required
            placeholder="Please enter your name..."
            type="text"
            name="username"
            value={inputsValue.username}
            id=""
          />
          <input
            required
            placeholder="Please enter your E-Mail..."
            type="email"
            name="email"
            value={inputsValue.email}
            id=""
          />
          <input
            placeholder="Please enter your Mobile number (Optional)"
            type="text"
            name="phonenumber"
            value={inputsValue.phonenumber}
            id=""
          />
          <textarea
            placeholder="How can i help you ?"
            required
            name="textarea"
            value={inputsValue.textarea}
            id=""
            cols="40"
            rows="7"
          ></textarea>
          {isSending ? (
            <input type="submit" value="..." disabled={isSending} />
          ) : (
            <input
              type="submit"
              value="Send"
              disabled={!isRecaptcha}
            />
          )}
        </form>
      </div>

      <div className="recaptcha">
        <ReCAPTCHA
          sitekey="6LcXllEhAAAAANcG7woVqCFiV_0f6uUz96pploap"
          onChange={() => setIsRecaptcha(true)}
        />
      </div>
    </div>
  );
}

export default Form;
